<template>
  <div>
    <v-container fluid>

      <v-overlay :value="overlay" opacity="0">
        <v-progress-circular
            color="primary"
            indeterminate
            size="50"
        ></v-progress-circular>
      </v-overlay>

      <div class="main">

        <div v-if="tab === 'one'">

          <v-row justify="center" align="center">

            <v-col sm="12" md="12" lg="11" xl="10">


              <v-row align="center">
                <v-col cols="12" lg="6" md="6"
                       v-if="can('supplier')"
                       class="text-lg-start text-md-start text-none custom-border-bottom">
                  <div class="d-flex align-center bg-subtle-gifty pa-2 rounded-lg gifty-text">
                    <v-avatar size="40" v-if="store.account && store.account.photo">
                      <v-img :src="fileUrl+store.account.photo"></v-img>
                    </v-avatar>
                    <v-avatar size="40" v-else color="gifty">
                      <v-icon size="25" dark>mdi-store</v-icon>
                    </v-avatar>
                    &nbsp;
                    Boutique : &nbsp;
                    <span class="font-weight-bold f-15">
                       <v-progress-circular indeterminate color="gifty" v-if="showLoading"></v-progress-circular>
                      {{ store.name }}
                    </span>
                  </div>
                </v-col>

                <v-spacer/>

                <v-col class="text-lg-end text-md-end text-none custom-border-bottom" cols="12" lg="6" md="6">
                  <div class="d-flex align-center bg-subtle-gifty pa-2 rounded-lg gifty-text pointer"
                       @click="callService">
                    <v-avatar size="40" color="gifty">
                      <v-icon size="25" dark>mdi-phone-log-outline</v-icon>
                    </v-avatar>
                    &nbsp;
                    Des questions ? appeler le : &nbsp;<span class="font-weight-bold f-15"> 0770 30 80 82 </span>
                  </div>
                </v-col>

              </v-row>

              <v-row align="stretch">
                <v-col sm="12" md="6" lg="6" xl="6" cols="12"
                       class="d-flex flex-column pointer"
                       @click="$refs.addOnlinePaymentDialog.open()">
                  <v-card class="shadow flex d-flex flex-column">
                    <v-card-text class="pa-6 flex">
                      <v-btn color="primary"
                             dark
                             small
                             depressed>
                        New
                      </v-btn>
                      <v-row align="center" justify="center" no-gutters>
                        <v-col cols="12" sm="12" md="7" lg="7" order-md="0" order-xl="0" order-lg="0" order-sm="1"
                               order="1">
                          <h4 class="h-4 grey--text text--darken-3 mb-3">
                            Envoyer une demande de paiement
                          </h4>
                          <p class="f-15">
                            FACILE ! Demandez le numéro de téléphone avec lequel le compte Gifty de l'utilisateur a été
                            créé.
                          </p>
                        </v-col>
                        <v-col cols="12" sm="12" md="5" lg="5">
                          <v-img width="100%"
                                 :src="require('@/assets/s2.svg')"/>
                        </v-col>
                      </v-row>


                      <div class="d-flex align-center justify-space-between">
                        <div class="d-flex align-center">
                          <v-btn color="gifty"
                                 @click.stop="openUrl('https://www.youtube.com/watch?v=rq5-S3YuvCY')"
                                 dark
                                 small
                                 height="40"
                                 depressed>
                            <v-icon left size="22">mdi-youtube</v-icon>
                            Comment <br> ça marche ?
                          </v-btn>
                          &nbsp;
                          <v-btn color="gifty"
                                 @click.stop="openUrl('https://drive.google.com/file/d/1ipl2fS0R53ZWz2KeauPOdYr6STvM4XED/view?usp=sharing')"
                                 text
                                 height="40"
                                 small>
                            <v-icon left size="22">mdi-file-pdf-box</v-icon>
                            Consulter <br> le PDF
                          </v-btn>
                        </div>
                        <v-btn color="gifty"
                               @click="$refs.addOnlinePaymentDialog.open()"
                               dark
                               icon
                               depressed>
                          <v-icon>mdi-arrow-right</v-icon>
                        </v-btn>
                      </div>

                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col sm="12" md="6" lg="6" xl="6" cols="12"
                       class="d-flex flex-column pointer"
                       @click="$refs.addPaymentDialog.open()">
                  <v-card class="shadow flex d-flex flex-column">
                    <v-card-text class="pa-6 flex">
                      <v-btn color="primary"
                             dark
                             small
                             depressed>
                        New
                      </v-btn>
                      <v-row align="center" justify="center" no-gutters>
                        <v-col cols="12" sm="12" md="7" lg="7" order-md="0" order-xl="0" order-lg="0" order-sm="1"
                               order="1">
                          <h4 class="h-4 grey--text text--darken-3 mb-2">
                            Générer un code QR de paiement
                          </h4>
                          <p class="f-15">
                            Il suffit de demander le numéro de téléphone utilisé pour créer le compte Gifty.
                          </p>
                        </v-col>
                        <v-col cols="12" sm="12" md="5" lg="5">
                          <v-img width="100%"
                                 :src="require('@/assets/s4.svg')"/>
                        </v-col>
                      </v-row>

                      <div class="d-flex align-center justify-space-between">
                        <div class="d-flex align-center">
                          <v-btn color="gifty"
                                 dark
                                 small
                                 @click.stop="openUrl('https://www.youtube.com/watch?v=e5H5I6dr030')"
                                 height="40"
                                 depressed>
                            <v-icon left size="22">mdi-youtube</v-icon>
                            Comment <br> ça marche ?
                          </v-btn>
                          &nbsp;
                          <v-btn color="gifty"
                                 text
                                 @click.stop="openUrl('https://drive.google.com/file/d/1BXW0rHK-6jeveCtI3XFVpot9oaCrvjce/view?usp=sharing')"
                                 height="40"
                                 small>
                            <v-icon left size="22">mdi-file-pdf-box</v-icon>
                            Consulter <br> le PDF
                          </v-btn>
                        </div>
                        <v-btn color="gifty"
                               dark
                               icon
                               @click="$refs.addPaymentDialog.open()"
                               depressed>
                          <v-icon>mdi-arrow-right</v-icon>
                        </v-btn>
                      </div>

                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col sm="12" md="6" lg="6" xl="6" cols="12"
                       @click="$router.push('/partner-vouchers/store/'+storeId+'/scan')"
                       class="d-flex flex-column pointer">
                  <v-card class="shadow flex d-flex flex-column">
                    <v-card-text class="pa-6 flex">
                      <v-row align="center" justify="center" no-gutters>
                        <v-col cols="12" sm="12" md="7" lg="7" order-md="0" order-xl="0" order-lg="0" order-sm="1"
                               order="1">
                          <h4 class="h-4 grey--text text--darken-3 mb-2">
                            Effectuer un paiement classique
                          </h4>
                          <p class="f-15">
                            Scannez le code du bon d'achat de l'utilisateur ou recopiez le code sur le bon
                            d'achat.
                          </p>
                        </v-col>
                        <v-col cols="12" sm="12" md="5" lg="5">
                          <v-img width="100%"
                                 :src="require('@/assets/s3.svg')"/>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col sm="12" md="6" lg="6" xl="6" cols="12"
                       @click="[tab = 'two',$vuetify.goTo(0), getPartnerVouchers()]"
                       class="d-flex flex-column pointer">
                  <v-card class="shadow flex d-flex flex-column">
                    <v-card-text class="pa-6 flex">
                      <v-row align="center" justify="center" no-gutters>
                        <v-col cols="12" sm="12" md="7" lg="7" order-md="0" order-xl="0" order-lg="0" order-sm="1"
                               order="1">
                          <h4 class="h-4 grey--text text--darken-3 mb-2">
                            Tableau de bord
                          </h4>
                          <p class="f-15">
                            Retrouvez tous les détails des transactions effectuées avec Gifty : statistiques,
                            historiques de paiements, etc.
                          </p>

                        </v-col>
                        <v-col cols="12" sm="12" md="5" lg="5">
                          <v-img width="100%"
                                 :src="require('@/assets/s1.svg')"/>
                        </v-col>
                      </v-row>
                      <div class="d-flex align-center justify-space-between">
                        <div></div>
                        <v-btn color="gifty"
                               dark
                               icon
                               depressed>
                          <v-icon>mdi-arrow-right</v-icon>
                        </v-btn>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </div>

        <div v-if="tab === 'two'">

          <div class="text-center custom-border-bottom mt-1 mb-6">
            <v-btn color="gifty"
                   class="rounded-lg"
                   dark
                   text
                   large
                   @click="[tab = 'one',$vuetify.goTo(0)]">
              <v-icon left size="22">mdi-arrow-left</v-icon>
              Retour à la page d'accueil
              <v-icon right size="22">mdi-home-outline</v-icon>
            </v-btn>
          </div>


          <StatisticCards ref="statisticCards"
                          :loading="isLoading"
                          :statistics="statistics"
                          :store-id="storeId"
                          class="mb-3"
                          @is-today="[isToday = true,getPartnerVouchers(),$vuetify.goTo('#table')]"/>


          <div class="text-end mb-3" v-if="can('supplier')">

            <v-btn color="gifty"
                   text
                   :to="'/partner-vouchers/store/' + storeId+'/scan-history'">
              <v-icon left>mdi-history</v-icon>
              H. des scans
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>

            <v-btn color="gifty"
                   text
                   :to="'/partner-vouchers/store/' + storeId+'/payment-history'">
              <v-icon left>mdi-credit-card-clock-outline</v-icon>
              H. des paiements
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>

          </div>


          <v-card id="table" class="shadow">

            <v-card-title>
              <div>
                <v-text-field
                    v-model="keyword"
                    append-icon="mdi-magnify"
                    clearable
                    dense
                    filled
                    hide-details
                    placeholder="Recherche..."
                    rounded
                    single-line
                ></v-text-field>
              </div>

              <v-spacer/>

              <div class="d-lg-block d-none">
                <v-btn color="grey"
                       text
                       @click="[dates = [], getPartnerVouchers()]">
                  <v-icon left>mdi-refresh</v-icon>
                  Mettre à jour
                </v-btn>

                <v-btn color="grey"
                       text
                       @click="$refs.filterDialog.open()">
                  <v-icon left>mdi-filter-outline</v-icon>
                  Filtre
                </v-btn>

                <v-btn :class="isToday ? 'v-btn--active' : ''"
                       :color="isToday ? 'primary' : 'grey'"
                       text
                       @click="[isToday = !isToday,getPartnerVouchers()]">
                  <v-icon left>mdi-clock-check-outline</v-icon>
                  Aujourd'hui
                </v-btn>
              </div>

            </v-card-title>

            <v-divider/>

            <v-card-text v-if="isLoading">
              <v-skeleton-loader type="table"/>
            </v-card-text>

            <div v-else>

              <v-card-text class="pa-0">

                <v-simple-table v-if="vouchers.length">
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th>Montant</th>
                      <th>Service</th>
                      <th>Téléphone</th>
                      <th class="text-no-wrap">Code du Bon d'achat</th>
                      <th class="text-no-wrap">Scanner le</th>
                      <th class="text-center" v-if="can('supplier')">Rembourser</th>
                      <th>Imprimer</th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr v-for="(item,i) in vouchers"
                        :key="i">

                      <td class="gifty--text font-weight-medium">
                        {{ item.voucher ? CurrencyFormatting(item.voucher.amount) : 'Remboursé' }}
                      </td>

                      <td class="font-weight-medium text-no-wrap">
                        {{ item.voucher && item.voucher.service_name ? item.voucher.service_name : '-' }}
                      </td>

                      <td>{{ item.phone || '-' }}</td>

                      <td class="primary--text font-weight-medium">
                        {{ item.voucher?.code }}
                      </td>

                      <td class="text-no-wrap">
                        {{ item.updated_at }}
                      </td>

                      <td class="text-center" v-if="can('supplier')">

                        <div v-if="!item.deleted_at">
                          <v-icon v-if="item.is_refunded" color="success">mdi-check-circle</v-icon>

                          <v-tooltip v-else bottom color="gifty">
                            <template v-slot:activator="{ on }">
                              <v-btn class="text-none"
                                     color="gifty"
                                     icon
                                     @click="refund(item.id)"
                                     v-on="on">
                                <v-icon>mdi-backup-restore</v-icon>
                              </v-btn>
                            </template>
                            <span>Rembourser</span>
                          </v-tooltip>
                        </div>

                        <div v-else>
                          <v-chip color="red" dark small>
                            <v-icon dark left small>mdi-close</v-icon>
                            Supprimé
                          </v-chip>
                        </div>

                      </td>

                      <td>
                        <v-tooltip bottom color="gifty">
                          <template v-slot:activator="{ on }">
                            <v-btn :disabled="!!(item.deleted_at)"
                                   color="gifty"
                                   icon
                                   @click="$refs.printContent.printPdf(item)"
                                   v-on="on">
                              <v-icon>mdi-cloud-print-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Imprimer</span>
                        </v-tooltip>
                      </td>

                    </tr>

                    </tbody>
                  </template>
                </v-simple-table>

                <div v-else>
                  <NoResults @reload="getPartnerVouchers"/>
                </div>

              </v-card-text>

              <v-divider/>

              <v-card-actions>

                <v-row align="center" justify="space-between" class="flex-wrap">

                  <v-col cols="12" lg="2" md="2">
                    <v-select
                        v-model="perPage"
                        :items="[10, 20, 30, 50, 100]"
                        dense
                        hide-details
                        label="Ligne par page"
                        outlined
                    ></v-select>
                  </v-col>

                  <v-spacer></v-spacer>

                  <v-col cols="12" sm="auto">
                    <v-pagination
                        v-model="pagination.current"
                        :length="pagination.total"
                        circle
                        total-visible="5"
                        @input="getPartnerVouchers"
                    ></v-pagination>
                  </v-col>
                </v-row>
              </v-card-actions>

            </div>


          </v-card>

        </div>

      </div>

      <FilterDialog ref="filterDialog" @filter="handleFilter"/>
      <AddPaymentDialog ref="addPaymentDialog" @success-payment="vouchers.unshift($event)"/>
      <AddOnlinePaymentDialog ref="addOnlinePaymentDialog" @success-payment="vouchers.unshift($event)"/>
      <PrintContent ref="printContent"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import FilterDialog from "./components/FilterDialog";
import StatisticCards from "./components/StatisticCards";
import AddPaymentDialog from "@/views/partner_vouchers/components/AddPaymentDialog";
import AddOnlinePaymentDialog from "@/views/partner_vouchers/components/AddOnlinePaymentDialog";
import PrintContent from "@/views/partner_vouchers/components/PrintContent";

export default {
  components: {
    PrintContent,
    AddPaymentDialog,
    AddOnlinePaymentDialog,
    NoResults,
    FilterDialog,
    StatisticCards
  },
  data() {
    return {
      isLoading: false,
      overlay: false,
      isToday: false,

      storeId: null,
      keyword: null,
      dates: [],
      total: 0,
      perPage: 10,
      pagination: {
        current: 1,
        total: 0
      },

      vouchers: [],
      statistics: {},
      store: {},
      id: null,
      tab: "one",
      fileUrl: process.env.VUE_APP_FILE_URL,
      showLoading: false,
    };
  },
  methods: {
    getPartnerVouchers() {
      this.isLoading = true
      HTTP.get('/v1/partner-vouchers?page=' + this.pagination.current, {
        params: {
          isToday: this.isToday,
          storeId: this.storeId,
          perPage: this.perPage,
          dates: this.dates,
          keyword: this.keyword
        }
      }).then(res => {
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;
        this.vouchers = res.data.data.data
        this.statistics = res.data.statistics
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    showStore() {
      this.showLoading = true
      HTTP.get('/v1/partner-vouchers/' + this.storeId + '/show').then(res => {
        this.store = res.data.data
        this.showLoading = false
      }).catch(err => {
        this.showLoading = false
        console.log(err)
      })
    },
    handleFilter(e) {
      this.dates = e.dates
      this.getPartnerVouchers()
    },
    refund(id) {
      this.id = id;
      this.$confirm_dialog_body = 'Êtes-vous sûr de vouloir REMBOURSER cet voucher';
      this.$confirm_dialog = true;
    },
    handleRefund() {
      this.overlay = true;
      HTTP.post("/v1/partner-vouchers/refund", {
        id: this.id,
      }).then(() => {
        this.$successMessage = "Cet voucher a été remboursé avec succès.";
        this.overlay = false;
        this.getPartnerVouchers()
        this.id = null;
      })
          .catch((err) => {
            this.overlay = false;
            console.log(err);
          });
    },
    callService() {
      window.open('tel:+213770308082');
    },
    openUrl(url) {
      window.open(url);
    },
  },
  watch: {
    perPage() {
      this.pagination.current = 1
      this.getPartnerVouchers()
    },
    keyword() {
      this.getPartnerVouchers()
    },
    '$route.params.id': function () {
      this.storeId = this.$route.params.id
      this.getPartnerVouchers()
    },
    $is_confirm: function (el) {
      if (el) {
        this.handleRefund();
        this.$is_confirm = false;
      }
    },
  },
  created() {
    this.storeId = this.$route.params.id
    this.getPartnerVouchers()
    this.showStore()
  },
};
</script>

<style scoped>
.h-4 {
  font-size: 1.2rem; /* Default size for desktop */
}

/* Tablet (medium) */
@media (max-width: 992px) {
  .h-4 {
    font-size: 1.14rem;
  }
}

/* Phone (small) */
@media (max-width: 600px) {
  .h-4 {
    font-size: 1.14rem;
  }
}

.custom-border-bottom::after {
  content: '';
  display: block;
  width: 60px;
  height: 4px;
  background-color: #d60c56;
  margin: 0 auto;
  border-radius: 5px;
}

.bg-subtle-gifty {
  background-color: rgba(214, 12, 86, 0.10);
}
</style>
